/* benefits section design */
.benefit-section{
    position: relative;
    
}
.overlay-benefits-contents{
position: absolute;
background-size: 40%;
top: 0;
right: 0;
width: 40%;
bottom: 0;
left: 60%;
background-repeat: no-repeat;
background-position: right center;
}
.overlay-benefits{
background: linear-gradient(180deg, #163654 0%, #031f3b 68%);
width: 60%;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
}
.benefits-contents{
display: none;
}
.benefits-contents.active{
display: block;
}
.solutions .aviation h5 {
min-height: 100px;
display: flex;
align-items: center;
justify-content: center;
padding: 10px;
text-align: center;
font-weight: 600;
font-size: 1.15rem;
text-decoration: none;
color:white;
cursor: pointer;
}
.solutions{
position: relative;
}
.solutions .col-sm-4:nth-child(odd) .aviation {
background: #fd6517;
border: 1px solid #fd6517;
margin-bottom: 1.2rem;
}

.solutions .aviation {
border: 1px solid #fd6517;
margin-bottom: 1.2rem;
}
.solutions .aviation:hover{
background: #fd6517;
border: 1px solid #fd6517;
}
.solutions .aviation h5:hover{
color:#fff
}
/* new design card */
.Benefits {
    height: 265px;
}
.Benefits {
    position: relative;
    width: 92%;
    overflow: hidden;
}
.Benefits .gradient {
    max-width: 100%;
    margin: auto;
}
.Benefits .gradient img {
    position: absolute;
    left: 50%;
    top: 50%;
 
    transform: translate(-50%, -50%);
    min-height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}
.Benefits .gradient:after {
    display: block;
    position: relative;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 62%, #000 96%);
    background-image: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0) 62%, #000 96%);
    margin-top: -150px;
    height: 150px;
    width: 100%;
    content: '';
    position: absolute;
    bottom: 0;
}
.theInfo {
    overflow: hidden;
    position: absolute;
}
.Benefits .theInfo {
    width: 100%;
    padding: 35px;
    height: 100%;
    bottom: -170px;
    background-color: rgba(0, 160, 233, 0);
    transition: all .5s ease-in-out;
}
.Benefits .theInfo .the-title {
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-shadow: rgba(0, 0, 0, .85) 0 1em 5em;
    margin-bottom: 12px;
    height: 48px;
    overflow: hidden;
}
.Benefits .theInfo .the-text {
    color: #fff;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    margin-top: 12px;
    border-top: 1px solid #fff;
    padding: 12px 12px 0;
}
.Benefits:hover .theInfo {
    bottom: 0;
    background-color: rgb(4 65 92 / 85%);
    /* background-color: rgba(0, 160, 233, .85); */
}
.theInfo {
    overflow: hidden;
}
.slick-dots li.slick-active button:before{
    opacity: 1;
    /* color: #000; */
    width: 44px;
    height: 15px;
    background: #00a0e9;
    margin-right: 30px;
    color: #00a0e9;
}
.slick-dots li button:before {
    /* font-size: 6px; */
    width: 40px;
    /* margin-left: 59px; */
    height: 15px;
    border-radius: 20px;
    background: #00a0e9;
    /* line-height: 20px; */
    position: absolute;
    top: 0;
    left: 0px;
    width: 15px;
    /* height: 20px; */
    content: '•';
    text-align: center;
    opacity: .25;
    color: #00a0e9;
}
.slick-next:before, .slick-prev:before{
    font-size: 50px;
}
.slick-prev:before{
    margin-left: -45px;
}
.slick-dots {
    bottom: -53px;
}
@media screen and (min-width: 0px) and (max-width: 425px) {
    .col-xs-6 {
        flex: 0 0 auto;
        width: 50% !important;
    }
}