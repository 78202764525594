.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* h2{
  color: white;
  text-align: center;
} */

.mainContainer{
  margin-left: 100px;
  margin-right: 100px;
}

.mainContainer .container{
  padding: 40px 0;
}

.mainContainer .container img{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}

/* div h3{
  color: white;
  font-weight: bold;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* @media (min-width:320px){
  .mainContainer{
    margin-left: 10px;
    margin-right: 10px;
  }
} */
@media only screen and (max-width: 600px) and (min-width: 320px) {
  .mainContainer{
    margin-left: 10px;
    margin-right: 10px;
  }

}
.scroll-to-top {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  cursor: pointer;
  background-color: #e7530a;
  color: #fff;
  width: 40px;
  height: 40px;
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.scroll-to-top i {
  font-size: 20px;
}