.navbar {
    background: #fff;
    box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, .12);
    border: none;
    border-radius: 0;
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

div#sticky {
    position: sticky;
    top: 0;
    z-index: 99999;

}

.nav-title {
    display: flex;
    text-overflow: ellipsis;
    flex-direction: row;
    width: 220px;
    font-weight: 600;
}

.large{
    font-size: larger;
}
.bold{
    font-weight: bold;
}
.zindex-2{
    z-index: 2000;
}

.background-color-primary{
    background-color: #58b0fc;
}
.fullwidth{
    width: 100%;
    
}

