/* application area */
.application-tab-container{
    /* transform: translateX(-30px) translateY(-30px); */
    transition-delay: .1s;
    transition: opacity 1s cubic-bezier(.23,1,.32,1) .5s, transform 2s cubic-bezier(.23,1,.32,1);
    flex: 0 0 370px;
    max-width: 370px;
    background-color: #0b3155;
          box-shadow: 0 2px 37px 0 #0006;
          display: inline-block;
          
          margin: 0;
        
          padding: 14px 20px 2px 0;
         
          vertical-align: top;
  }
  .application-content-div{
    padding: 60px 40px;
    width: auto;
    display: none;
    position: relative;
  }
  .application-content-div p{
   display: none;
    }
    .application-content-div.active{
      display: block;
      }
    .application-content-div.active p{
        display: block;
        }
        .a-application__tabs-label {
          padding: 16px 14px 16px 50px;
          background-color: #0b3155;
          color: #fff;
          cursor: pointer;
          display: block;
          font-size: 18px;
          font-weight: 600;
          height: 100%;
          text-decoration: none;
          width: 100%;
      }
      .a-application__tabs-label:focus{
        color: #fff;
      }
      .a-application__tabs-label:hover{
        text-decoration: underline;
      }
     .a-application__tabs-label.active {
        transform: translateX(30px);
    }
      .a-application__tabs-label.active {
        background: #114360;
        box-shadow: 0 2px 20px 0 #0000004d;
        color: #fff;
        padding: 16px 44px 16px 20px;
        text-decoration: none;
        transform: translateX(20px);
        width: calc(100% + 20px);
    }