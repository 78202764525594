html{
  overflow-x: hidden;
}
footer.site-footer .footer-inner {
  font-size: 13px;
  line-height: 1;
  padding-block: 12px;
}

.bg-white {
  background-color: #fff;
}

.font-small {
  font-size: small;
}

.font-medium {
  font-size: 14px;
}

.primary {
  background-color: #63afab;
  color: white;
}

.warning {
  color: #ff0000;
}

footer.site-footer {
  margin-left: -68px;
  position: fixed;
  bottom: 0%;
  width: 100%;
}

.dropdownMenu {
  width: 150px;
  color: black;
  padding: 6px 0 5px 20px;
  margin: 5px;
}

.dropdownMenu:hover {
  background-color: rgb(230, 227, 227);
}

.divider {
  background: rgb(179, 177, 177);
  height: 0.08px;
  margin-inline: 10px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100vh;
  /* min-height: 900px; */
  display: flex;
  background: cover;
  background-repeat: no-repeat;
  background-size: cover;
}

.small-width {
  width: 80%;

  align-items: center;
}

.full-margin-block {
  margin-block: 9%;
}

@media (max-width: 768px) {
  .font-small {
    font-size: 10px;
  }
}

.footer {
  font-size: 14px;
  text-align: center;
  justify-content: center;

}

.nav {
  float: inline-end
}

.landing_footer {
  margin: auto;
  display: block;
  background: linear-gradient(180deg, #163654 0%, #031f3b 68%);
  padding: 20px;
}

.footer-para {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0px;
}

.social li:hover {
  transform: scale(1.15) rotate(360deg);
}

.social li a {
  color: #EDEFF1;
}

.social li:hover {
  border: 2px solid #2c3e50;
  background: #2c3e50;
}

.social li a i {
  font-size: 16px;
  margin: 0 0 0 5px;
  color: #EDEFF1 !important;
}

.footer-bottom {
  background: #021930;
  padding-top: 10px;
  padding-bottom: 10px;
}

.footer-bottom p.pull-left {
  padding-top: 6px;
}

.landing_footer {
  padding-top: 30px;
  background: linear-gradient(180deg, #163654 0%, #031f3b 68%);
  height: auto;
  padding-bottom: 20px;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #4e4e4e;
  text-decoration: none;
}

.landing_footer p {
  margin: 0;
  color: #fff;
  font-size: 14px;
  margin-top: 20px;
}

/* .landing_footer img {
  max-width: 100%;
  padding-top: 55px;
  
} */

.landing_footer h3 {
  /* border-bottom: 1px solid #BAC1C8; */
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  padding: 0px 0px 15px 0px;
  text-transform: capitalize;
}

.landing_footer ul {
  font-size: 13px;
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 15px;
  color: #7F8C8D;
  text-decoration: none;
}

/* .landing_footer ul:hover {
        color: #fe8464;
      } */

.landing_footer ul li a {
  padding: 0 0 10px 0;
  display: block;
  font-size: 13px;
  text-decoration: none;
}

.landing_footer ul li a:hover {
  color: #f8922b;
}

.landing_footer a {
  color: #fff
}
@media only screen and (max-width: 600px){
  .landing_footer{
    text-align: center;
  }
  .social{
    margin-left: 60px;
  }
 
}
.supportLi h4 {
  font-size: 20px;
  font-weight: lighter;
  line-height: normal;
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

.newsletter-box input#appendedInputButton {
  background: #FFFFFF;
  display: inline-block;
  /* float: left; */
  height: 40px;
  clear: both;
  width: 100%;
}

.newsletter-box .btn {
  border: medium none;
  border-radius: 3px;
  display: inline-block;
  height: 21px;
  width: 103%;
  padding: 0;
  color: #fff;
  margin-left: -5px;
  background-color: #e7782d;
}

.newsletter-box {
  overflow: hidden;
}

.bg-gray {
  background-image: -moz-linear-gradient(center bottom, #BBBBBB 0%, #F0F0F0 100%);
  box-shadow: 0 1px 0 #B4B3B3;
}

.social li {

  border-radius: 10px;
  float: left;
  height: 36px;
  line-height: 36px;
  margin: 0 8px 0 0;
  padding: 0;
  text-align: center;
  width: 36px;
  transition: all 0.5s ease 0s;
  text-decoration: none;

}

.social li:hover {
  transform: scale(1.15) rotate(360deg);
}

.social li a {
  color: #EDEFF1;
}

.social li:hover {
  border: 2px solid #2c3e50;
  background: #2c3e50;
}

.social li a i {
  font-size: 16px;
  margin: 0 0 0 5px;
  color: #EDEFF1 !important;
}

.footer-bottom {
  
  padding-top: 10px;
  padding-bottom: 10px;
}

.footer-bottom p.pull-left {
  padding-top: 6px;
}



a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #6f6f6f;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  /* width: 80px; */
}

section {
  padding: 60px 0;
  /* min-height: 100vh;*/
}

.sec-title {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}

.sec-title .title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #1f8754b0;
  font-weight: 500;
  margin-bottom: 15px;
}

.sec-title h2 {
  position: relative;
  display: block;
  /* font-size: 30px; */
  line-height: 1.28em;
  color: #222222;
  font-weight: 600;
  padding-bottom: 18px;
}

.sec-title h2:before {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 3px;
  background-color: #d1d2d6;
}

.sec-title .text {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #848484;
  font-weight: 400;
  margin-top: 35px;
}

.sec-title.light h2 {
  color: #ffffff;
}

.sec-title.text-center h2:before {
  left: 50%;
  margin-left: -25px;
}

.list-style-one {
  position: relative;
}

.list-style-one li {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #222222;
  font-weight: 400;
  padding-left: 35px;
  margin-bottom: 12px;
}

.list-style-one li:before {
  content: "\f058";
  position: absolute;
  left: 0;
  top: 0px;
  display: block;
  font-size: 18px;
  padding: 0px;
  color: #ff2222;
  font-weight: 600;
  -moz-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1.6;
  font-family: "Ysabeau Infant" !important;
  /* font-family: "Font Awesome 5 Free"; */
}

.list-style-one li a:hover {
  color: #44bce2;
}

.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 30px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: #1f8754b0;
  text-decoration: none;
}

.btn-style-one:hover {
  background-color: #0794c9;
  color: #ffffff;
}

.about-section {
  position: relative;
  /* padding: 190px 0 0px; */
}

.about-section .sec-title {
  margin-bottom: 45px;
}

.about-section .content-column {
  position: relative;
  margin-bottom: 0px;
}

.about-section .content-column .inner-column {
  position: relative;
  padding-left: 30px;
  padding-top: 30px;
}

.about-section .text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
  /* color: #848484; */
  font-weight: 400;

}

.about-section .list-style-one {
  margin-bottom: 45px;
}

.about-section .btn-box {
  position: relative;
}

/* .about-section .btn-box a{
padding: 15px 50px;
} */

.about-section .image-column {
  position: relative;
  margin-top: 50px;
}

.about-section .image-column .text-layer {
  position: absolute;
  right: -110px;
  top: 50%;
  font-size: 325px;
  line-height: 1em;
  color: #ffffff;
  margin-top: -175px;
  font-weight: 500;
}

.about-section .image-column .inner-column {
  position: relative;
  padding-left: 80px;
  padding-bottom: 0px;
}

.about-section .image-column .inner-column .author-desc {
  position: absolute;
  bottom: 16px;
  z-index: 1;
  background: #fd6517;
  padding: 10px 15px;
  left: 96px;
  width: calc(100% - 120px);
  border-radius: 50px;
}

.about-section .image-column .inner-column .author-desc h2 {
  font-size: 21px;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  margin: 0;
}

.about-section .image-column .inner-column .author-desc span {
  font-size: 16px;
  letter-spacing: 6px;
  text-align: center;
  color: #fff;
  display: block;
  font-weight: 400;
}

.about-section .image-column .inner-column:before {
  content: '';
  position: absolute;
  width: calc(50% + 80px);
  height: calc(100% + 160px);
  top: -80px;
  left: -3px;
  background: transparent;
  z-index: 0;
  border: 44px solid #fd6517;
}

.about-section .image-column .image-1 {
  position: relative;
  margin: 80px;
}

.about-section .image-column .image-2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.about-section .image-column .image-2 img,
.about-section .image-column .image-1 img {
  box-shadow: 0 30px 50px rgba(8, 13, 62, .15);
  border-radius: 46px;
}

.about-section .image-column .video-link {
  position: absolute;
  left: 70px;
  top: 170px;
}

.about-section .image-column .video-link .link {
  position: relative;
  display: block;
  font-size: 22px;
  color: #191e34;
  font-weight: 400;
  text-align: center;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 30px 50px rgba(8, 13, 62, .15);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.about-section .image-column .video-link .link:hover {
  background-color: #191e34;
  color: #fff;
}

.card {
  margin-bottom: 2rem;
}

.headline {
  left: 130px;

}

/* .clipped {
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
} */

@media screen and (max-width: 650px) {
  .headline {
    left: 20px;
  }

  .clipped {
    clip-path: none;
    max-height: 300px !important;
  }
}



.cd-1 {
  background-color: #01ced1 !important;
  color: white;
}

.cd-2 {
  background-color: #9470dc !important;
  color: white;
}

.cd-3 {
  background-color: #f4a361 !important;
  color: white;
}

.contact .info-box {
  color: #444444;
  background: #fafbff;
  padding: 30px;
}

.contact .info-box i {
  font-size: 38px;
  line-height: 0;
  color: #1f8754ba;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  background: #fafbff;
  padding: 30px;
  height: 100%;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #4154f1;
}

/* .contact .php-email-form input {
  padding: 10px 15px;
} */

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #fd6517;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.green_icon {
  color: green;
  font-size: 30px;
}

.dashboard_card_body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background_light_green {
  background-color: #e6f7e7;
}

.contact .php-email-form button[type=submit]:hover {
  background: #5969f3;
}

.text-none {
  text-decoration: none;
}

.card-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.services {
  padding: 60px 0;
}

.services .service-item {
  padding: 35px;
  height: 100%;
  border-radius: 10px;
 
  box-shadow: 0 2px 15px 0 #0000001a;
  
}
.services .service-item::after{
  transition: box-shadow .3s ease-in-out, border-color .3s ease-in-out;
}
.services .service-item:after {

  bottom: 0;
  
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: box-shadow .3s ease-in-out, border-color .3s ease-in-out;
}
.services .service-item:hover:after {
  box-shadow: 0 2px 25px 0 #0003;
}
.services .service-item:hover::after{
  box-shadow: 0 2px 25px 0 #0003;
  border-bottom-color: #fd6517;
}
.services .service-item:before {
  background-color: #fd6517;
  bottom: 0;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: 100%;
  transition: right .4s ease-in-out;
}
.services .service-item:hover:before {
  right: 0;
}
.services .service-item .icon {
  /* width: 48px;
  height: 48px; */
 
  position: relative;
  margin-bottom: 50px;
}

.services .service-item .icon img {
  color: var(--color-default);
 width: 100%;
 height: 100%;
  transition: ease-in-out 0.3s;
  z-index: 2;
  position: relative;
}



.services .service-item h3 {
  color: #222;
  font-weight: 700;
  margin: 0 0 20px 0;
  padding-bottom: 8px;
  
  position: relative;
  display: inline-block;
 
  transition: 0.3s;
}

.services .service-item p {
  line-height: 24px;
  margin-bottom: 0;
  text-align: justify;
}

.services .service-item .readmore {
  margin-top: 15px;
  display: inline-block;
  color: var(--color-primary);
}
.services .service-item:hover {
  background: #fff;
}

/* .back1 {
  background-image: url("Images/bg3.jpg");
  background-position: center;
  background-size: cover;
} */

.rounded-image {
  border-radius: 100px;
  height: 55px;
  width: 55px;
}

.profile-pic {
  border-radius: 50%;
  height: 120px;
  width: 120px;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  vertical-align: middle;
  text-align: center;
  color: transparent;
  transition: all .3s ease;
  text-decoration: none;
  cursor: pointer;
}

.profile-pic:hover {
  background-color: rgba(16, 235, 89, 0.5);
  z-index: 2;
  color: #fff;
  transition: all .3s ease;
  text-decoration: none;
}

.profile-pic span {
  /* display: inline-block; */
  padding-top: 4.5em;
  padding-bottom: 4.5em;
  padding-left: 2.5rem;
  color: black;
}

form input[type="file"] {
  display: none;
  cursor: pointer;
}

form p {
  font-size: 120%;
}

.ctrlqHeaderMast {
  height: 278px;
  background: #009688;
  display: flex;
  background: cover;
  background-repeat: no-repeat;
  background-size: cover;

}

.ctrlqFormContent {
  color: rgba(0, 0, 0, .87);
  padding: 20px 35px
}

.ctrlqFormContentWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-flex-direction: column;
  flex-direction: column
}

.ctrlqAccent {
  background-color: #a7ffeb;
  height: 8px;
}

.ctrlqCenteredContent {
  margin: auto;
  width: 600px;
}

.ctrlqFormCard {
  background-color: #fff;
  margin-bottom: 48px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  word-wrap: break-word
}

.ctrlqFormCard:first-of-type {
  margin-top: -100px
}

.ctrlqHeaderTitle {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 34px;
  line-height: 135%;
  max-width: 100%;
  min-width: 0%;
  margin-bottom: 22px
}

@media (max-width: 660px) {
  .ctrlqHeaderMast {
    width: 100%;
    height: 110px !important;
  }

  .ctrlqFormCard:first-of-type {
    margin-top: -50px;
  }

  .ctrlqCenteredContent {
    width: 90%;
  }
}

div.error {
  position: relative;
  top: -1rem;
  left: 0rem;
  font-size: 0.8rem;
  color: #FF4081;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}

@media (max-width: 3000px) {
  .ctrlqHeaderMast {
    height: 222px;
  }

  .ctrlqFormCard:first-of-type {
    margin-top: -50px;
  }

  .ctrlqCenteredContent {
    width: 90%;
  }
}

.icon img {
  height: 50px;
  width: 100px;
}


.button4 {
  border-radius: 50px;
  height: 60px;
  width: 300px;
  background-color: transparent;
}

.button4:hover {
  background-color: #ef5102;
  color: white;
}







/*easy steps css*/


/*easy steps css*/

/*animestion*/

/******************* FOOTER *******************/

/*::selection {
  background-color: salmon;
  color: white;
}*/





slider-area h2 {
  text-align: center;
  /* font-family: impact; */
  font-family: "Ysabeau Infant";
  font-size: 60px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 100px 0 30px 0;
  color: #fff;
}

.item {
  animation: animate 25s alternate linear infinite;
}

.container:hover .item {
  animation-play-state: paused;
}

@keyframes animate {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-1100px, 0, 0);
  }
}

@media (max-width:767px) {
  .slider-area h2 {
    font-size: 30px;
  }

  .wrapper {
    width: 95%;
    border-radius: 0;
    padding: 0;
  }
}

.slider {

  margin: auto;
  overflow: visible;
}

.slider ul {
  display: flex;
  padding: 0;
  animation: cambio 10s infinite linear;

}

.slider li {
  padding: 0 10px;
  list-style: none;
}

.slider img {
  height: 80px;
  width: 100px;
}

@keyframes cambio {
  from {
    margin-left: -10%
  }

  to {
    margin-left: -120%
  }
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  #div-mobile {
    display: block;
  }
 
  #div-desktop {
    display: none;
  }
}

@media screen and (min-width: 701px) and (max-width: 3000px) {
  #div-mobile {
    display: none;
  }

  #div-desktop {
    display: block;
  }

}
.home-section{
  padding:80px 0 180px 0;
  background-color:white
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.marquee h2 {
  position: absolute;
  color: #5b8ac4;
  /* float: left; */
  font-weight: bolder;
  height: 100%;
  text-align: center;
  background-color: #fff;
  text-transform: uppercase;
  line-height: 50px;
  /* padding: 0 12px; */
  border-radius: 2px 0 0 2px;
  z-index: 9;
  font-size: 15px;
  margin: 0;
}

.logoMarqueeSection {
  /* padding-top: 50vh;
  padding-bottom: 150vh; */
}

#logoMarqueeSection {
  max-width: 1920px !important;
  margin: 0 auto;
}

.default-content-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  width: 100%;
  min-height: 100vh;
}

div.marquee>a>img {
  height: 120px;
}

.logoMarqueeSection>div>div {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 0;
}

.marquee-wrapper {
  display: inline-block;
  white-space: nowrap;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  transform: translate3d(0%, 0, 0);
  animation-name: marquee;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.marquee a {
  display: inline-block;
  white-space: nowrap;
  padding-right: 5.4rem;
}

.marquee-wrapper:hover .marquee {
  animation-play-state: paused !important;
}

@keyframes marquee {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.sliderimg {
  width: 100px;
  height: 100px;
  animation: scroll 6s linear infinite;
}

.slide-track {
  width: 100%;
  display: flex;
  gap: 3em;
  overflow: hidden;
}

.slider {
  margin-top: 70px;
  background-color: whitesmoke;
  padding: 8em 2em;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translatex(-1000%)
  }
}

#hero {
  width: 100%;
  height: 70vh;
  background: #ffffff;
  /* border-bottom: 2px solid #fcebe3; */
  /* margin: 20px 0 -72px 0; */
}

#hero h1 {
  margin: 1.5rem 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #06475f;
}

#hero h2 {
  /* margin: 15px 0 0 0; */
}

#hero .btn-get-started {
  /* font-family: "Raleway", sans-serif; */
  font-family: "Ysabeau Infant";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 3px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: #fd6517;
  text-decoration: none;
}

#hero .btn-get-started:hover {
  background: #ef7f4d;
  border-radius: 10px 0px 10px 0px;
  color: #000;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 991px) {
  #hero {
    height: calc(100vh - 72px);
  }

  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

@media (max-height: 600px) {
  #hero {
    height: 120vh;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}


.navimg .img {
  height: 50px;
  width: 200px important !;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* .container {
  padding: 2px 16px;
} */


a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #333;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*--blog----*/

.sec-title {
  position: relative;
  margin-bottom: 70px;
}

.sec-title .title {
  position: relative;
  display: block;
  font-size: 16px;
  /* line-height: 1em; */
  color: #ff8a01;
  font-weight: 500;
  background: rgb(247, 0, 104);
  background: -moz-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
  background: -webkit-linear-gradient(to left, rgba(247, 0, 104, 1) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
  background: linear-gradient(to left, rgba(247, 0, 104) 0%, rgba(68, 16, 102, 1) 25%, rgba(247, 0, 104, 1) 75%, rgba(68, 16, 102, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 15px;
}

.sec-title h2 {
  position: relative;
  display: inline-block;
  font-size: 2.5rem;
  line-height: 1.2em;
  color: #1e1f36;
  font-weight: 700;
}

.sec-title .text {
  position: relative;
  font-size: 16px;
  line-height: 28px;
  color: #888888;
  margin-top: 30px;
}

.sec-title.light h2,
.sec-title.light .title {
  color: #ffffff;
  -webkit-text-fill-color: inherit;
}

.pricing-section {
  position: relative;
  /* padding: 100px 0 80px; */
  overflow: hidden;
}

.outer-box .pricing-block{
  width: 20%;
  padding: 0px 6px;
}
.pricing-block {
  position: relative;
  padding: 0 30px;
}

.pricing-block .inner-box {
  position: relative;
  /* background-color: #ffffff; */
  /* box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08); */
  padding: 0px 0 0;
  max-width: 370px;
  margin: 0 auto;
  /* border-top: 60px solid #40cbb4; */
}

.pricing-block .icon-box {
  position: relative;
  padding: 10px;
  background-color: #109d28;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
}

/* #0467cd */
/* .pricing-block .icon-box:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 75px;
  width: 100%;
  border-radius: 50% 50% 0 0;
  background-color: #ffffff;
  content: "";
} */


.pricing-block .icon-box .icon-outer {
  position: relative;
  height: 40px;
  width: 150px;
 
  margin: 0 auto;
  
}

.pricing-block .icon-box i {
  position: relative;
  display: block;
  height: 130px;
  width: 130px;
  line-height: 120px;
  border: 5px solid #40cbb4;
  border-radius: 50%;
  font-size: 50px;
  color: #40cbb4;
  -webkit-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  transition: all 600ms ease;
}

.pricing-block .inner-box:hover .icon-box i {
  transform: rotate(360deg);
}

.pricing-block .price-box {
  position: relative;
  text-align: center;
  padding: 10px 20px;
  /* rgb(43, 50, 69); */
  background-color: #2D3544;
  color: rgb(255, 255, 255);
  padding: 30px;
}

.pricing-block .title {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  /* color: #222222; */
  font-weight: 600;
}

.pricing-block .price {
  display: block;
  font-size: 30px;
  font-weight: 700;
 /* filter: blur(2px); */
}


.pricing-block .features {
  position: relative;
  margin: 0;
  padding: 0px 40px;
  background: #2D3544;
  color: rgb(255, 255, 255);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pricing-block .features li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #c8ceed;
  font-weight: 500;
  padding: 14px 0;
  padding-left: 30px;
  border-bottom: 1px solid rgb(57, 66, 88);
}

.pricing-block .features li:before {
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 16px;
  color: #2bd40f;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f058";
  /* font-family: "Font Awesome 5 Free"; */
  font-family: "Ysabeau Infant";
  margin-top: -8px;
}

.pricing-block .features li.false:before {
  color: #e1137b;
  content: "\f00d";
}

.pricing-block .features li a {
  color: #848484;
}

.pricing-block .features li:last-child {
  border-bottom: 0;
}

/* project frontend */
.pricing-block .pricing-list {
  position: relative;
  margin: 0;
  padding: 0px 40px;
  background: #2D3544;
  color: rgb(255, 255, 255);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pricing-block .pricing-list li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #c8ceed;
  font-weight: 500;
  padding: 5px;
 
  border-bottom: 1px solid rgb(57, 66, 88);
}




.pricing-block .pricing-list li a {
  color: #848484;
}

.pricing-block .pricing-list li:last-child {
  border-bottom: 0;
}


.pricing-block .btn-box {
  position: relative;
  text-align: center;
}

.pricing-block .btn-box a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  font-weight: 500;
  padding: 8px 30px;
  background-color: #40cbb4;
  border-radius: 10px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 300ms ease;
}
.price-pack{
  width: 17%;
}
.pricing-block .btn-box a:hover {
  color: #ffffff;
}

.pricing-block .inner-box:hover .btn-box a {
  color: #40cbb4;
  background: none;
  border-radius: 0px;
  border-color: #40cbb4;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .inner-box {
  border-color: #ff6038;
}
/* .pricing-block:nth-child(2) .features li {
  filter: blur(2px);
} */
.pricing-block:nth-child(2) .btn-box a,
.pricing-block:nth-child(2) .icon-box {
  background-color: #ff6038;
  /* filter: blur(2px); */
}
.pricing-block:nth-child(2) .get-button{
  background-color: #ff6038 !important;
}
.pricing-block:nth-child(4) .icon-box i,
.pricing-block:nth-child(4) .inner-box {
  border-color: #ffb828;
}
.pricing-block:nth-child(4) .btn-box a,
.pricing-block:nth-child(4) .icon-box {
  background-color: #ffb828;
  /* filter: blur(2px); */
}
.pricing-block:nth-child(4) .get-button{
  background-color: #ffb828 !important;
}
.pricing-block:nth-child(1) .icon-box i,
.pricing-block:nth-child(1) .inner-box {
  border-color: #0f6c8f;
}
.pricing-block:nth-child(1) .btn-box a,
.pricing-block:nth-child(1) .icon-box {
  background-color: #0f6c8f;
  /* filter: blur(2px); */
}
.pricing-block:nth-child(1) .get-button{
  background-color: #0f6c8f !important;
}
.pricing-block:nth-child(2) .inner-box:hover .btn-box a {
  color: #1d95d2;
  background: none;
  border-radius: 0px;
  border-color: #1d95d2;
}



.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .inner-box {
  border-color: #1b57f1;
}

.pricing-block:nth-child(3) .btn-box a,
.pricing-block:nth-child(3) .icon-box {
  background-color: #1b57f1;
  /* filter: blur(2px); */
}
.pricing-block:nth-child(3) .get-button{
  background-color: #1b57f1 !important;
}
/* .pricing-block:nth-child(3) .features li {
  filter: blur(2px);
}
.pricing-block:nth-child(4) .features li {
  filter: blur(2px);
}
.pricing-block:nth-child(5) .features li {
  filter: blur(2px);
} */
.pricing-block:nth-child(3) .inner-box:hover .btn-box a {
  color: #ffc20b;
  background: none;
  border-radius: 0px;
  border-color: #ffc20b;
}

.customed-form:hover {
  transform: scale(1.1);
}
/* 
.my-scrollbar::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
.my-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.my-customed-sidebar {
  background-color: #63afab;
  color: #fff;
  border-radius: 5px;
}

@media screen and (min-width: 992px) {
  .custom-form {
    padding-inline: 10%;
  }
}

::-webkit-scrollbar {
  display: auto;
}


.form-template-style {
  width: 48%;
  padding: 10px;
  font-weight: 500;
  font-size: medium;
  cursor: pointer;
}

.form-template-style:hover {
  /* background-color: #f2f2f2; */
  background-color: rgb(5 123 138);
  color: #fff;
}

a {
  text-decoration: none;
}

.floating_btn {
  position: fixed;
  top: 40px;
  right: 0px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

.contact_icon {
  background-color: rgb(229, 95, 12);
  color: #fff;
  width: 50px;
  height: 50px;
  font-size: 25px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translatey(0px);
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 rgb(229, 95, 12);
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-weight: normal;
  /* font-family: sans-serif; */
  font-family: "Ysabeau Infant";
  text-decoration: none;
  transition: all 300ms ease-in-out;
  z-index: 100;
}


.text_icon {
  margin-top: 8px;
  color: #707070;
  font-size: 13px;
}

.jeev {
  right: -40px !important;
}

.floating_btn2 {
  position: fixed;
  top: 40px;
  right: -40px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* card */

.blog-card {
  /* max-width: 550px; */
  width:300px;
  height: 400px;
  /* position: absolute; */
  /* font-family: 'Droid Serif', serif; */
  font-family: "Ysabeau Infant";
  color:#fff;
  /* top: 20%; */
  /* right: 0;   */
  /* left: 20px; */
  margin: 0 auto;
  overflow: hidden;
  border-radius: 0px;
  box-shadow: 0px 10px 20px -9px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition:all 0.4s;
  background: url(https://img.freepik.com/free-photo/arc-welding-steel-construction-site_2831-695.jpg?w=1060&t=st=1687177446~exp=1687178046~hmac=e50c6cbf68c474b349a48d063a34c872b5027991ec41750f13d8152582dc3302) center no-repeat;
  background-size: cover;
  opacity: 1;
  margin-top: 10px;
}
.blog-card a{ 
color:cornsilk;
  text-decoration:none;
  transition:all 0.2s
}
.blog-card .color-overlay {
  background: rgba(64, 84, 94,0.5);
  width: 550px;
  height: 500px;
  /* position: absolute; */
  z-index: 10;
  top: 0;
  left: 0;
  transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}
.blog-card .gradient-overlay {  
  background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.6) 21%);
  width: 550px;
  height: 500px;
  /* position: absolute; */
  top: 350px;
  left: 0;
  z-index: 15;
}
.blog-card:hover{
    box-shadow: 0px 18px 20px -9px rgba(0, 10, 30, 0.75);
}
.blog-card:hover .card-info {
  opacity: 1;
  bottom: 100px;
}
.blog-card:hover .color-overlay {
  background: rgba(64, 64, 70,0.8);
}
.blog-card:hover .title-content{
  margin-top:15px
}
.title-content {
  text-align: center;
  margin: 170px 0 0 0;
  /* position: absolute; */
  z-index: 20;
  width: 100%;
  top: 0;
  left: 0;
  transition:all 0.6s
}

.blog-card:hover h3:after{
 
  animation: changeLetter 0.3s 1 linear;
  width:80%
}

.blog-card h3,h1 {
  font-size: 1.9em;
  font-weight: 400;
  letter-spacing: 1px;
  /* font-family: 'Abril Fatface', serif; */
  font-family: "Ysabeau Infant";
  margin-bottom: 0;
  display:inline-block;
}
.blog-card h3 a{  
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);  
  transition:all 0.2s
}
.blog-card h3 a:hover{
  text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.95);
}
.case:after {
  content: " ";
  display: block; 
  width: 10%;
  height: 2px;
  margin: 20px auto;
  border: 0;
  background: #BDA26B;
  transition:all 0.2s
}

@keyframes changeLetter {
  0% {
    width: 10%;
  }
  100% {
    width: 80%;
  }
}

.intro {
  width: 170px;
  margin: 0 auto;
  color: #ddd;
  font-style: italic;
  line-height: 18px;
}
.intro a{
  color: #ddd
}
.intro a:hover{
  text-decoration:underline
}
.card-info {
   box-sizing: border-box;
  padding: 0;
  width: 100%;
  /* position: absolute; */
  bottom: -40px;
  left: 0;
  margin: 0 auto;
  padding: 0 50px;
  font-style: 16px;
  line-height: 24px;
  z-index: 20;
  opacity: 0;
  background-color: #01060682;
  height:100%;
  transition: bottom 0.64s, opacity 0.63s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.card-info a{
  display:block;
  width:100px;
  margin:15px auto;
  background:#fff;
  color:#444;
  padding:3px 10px;
  border-radius:2px;
  font-size:0.8em
}
.card-info a:hover{
  background: #8e7c49;
  color:#fff;
}
.card-info a:hover span{
   filter: brightness(10);
   opacity:1
}

/* @media screen and (min-width: 992px){
  .blog-card{}
} */

/* 
@media (max-width:750px)
{
  .utility-info {
    text-align:center;
  }
  .utility-info ul{
    width:100%;
    margin:0;
    box-sizing:border-box
  }
  .utility-info li{
    width:49%;
    display:inline-block;
    box-sizing:border-box;
    margin:0
  }
}

@media (max-width:500px){
  .utility-info li:last-of-type{
    width:100%
  }
  .card-info{
    display:none
  }
  .blog-card:hover .title-content,.title-content{
  margin-top:40px
}
.blog-card{
  height:300px
}
.blog-card h3{
  font-size:1.3em
}
.intro{
  font-size:0.8em
}
} */

.blog-card-1 {
  /* max-width: 550px; */
  width:300px;
  height: 400px;
  /* position: absolute; */
  /* font-family: 'Droid Serif', serif; */
  font-family: "Ysabeau Infant";
  color:#fff;
 /* top: 20%;  */
  /* right: 0;   */
  /* left: 60px; */
  margin: 0 auto;
  overflow: hidden;
  border-radius: 0px;
  box-shadow: 0px 10px 20px -9px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition:all 0.4s;
  background: url(https://images.pexels.com/photos/7567440/pexels-photo-7567440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1) center no-repeat;
  background-size: cover;
  opacity: 1;
  margin-top: 10px;
}
.blog-card-1 a{ 
color:cornsilk;
  text-decoration:none;
  transition:all 0.2s
}
.blog-card-1 .color-overlay-1 {
  background: rgba(64, 84, 94,0.5);
  width: 550px;
  height: 500px;
  /* position: absolute; */
  z-index: 10;
  top: 0;
  left: 0;
  transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}
.blog-card-1 .gradient-overlay-1 {  
  background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.6) 21%);
  width: 550px;
  height: 500px;
  /* position: absolute; */
  top: 350px;
  left: 0;
  z-index: 15;
}
.blog-card-1:hover{
    box-shadow: 0px 18px 20px -9px rgba(0, 10, 30, 0.75);
}
.blog-card-1:hover .card-info-1 {
  opacity: 1;
  bottom: 100px;
}
.blog-card-1:hover .color-overlay-1 {
  background: rgba(64, 64, 70,0.8);
}
.blog-card-1:hover .title-content-1{
  margin-top:15px
}
.title-content-1 {
  text-align: center;
  margin: 170px 0 0 0;
  /* position: absolute; */
  z-index: 20;
  width: 100%;
  top: 0;
  left: 0;
  transition:all 0.6s
}

.blog-card-1:hover h3:after{
 
  animation: changeLetter 0.3s 1 linear;
  width:80%
}

.blog-card-1 h3,h1 {
  font-size: 1.9em;
  font-weight: 400;
  letter-spacing: 1px;
  /* font-family: 'Abril Fatface', serif; */
  font-family: "Ysabeau Infant";
  margin-bottom: 0;
  display:inline-block;
}
.blog-card-1 h3 a{  
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);  
  transition:all 0.2s
}
.blog-card-1 h3 a:hover{
  text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.95);
}

@keyframes changeLetter {
  0% {
    width: 10%;
  }
  100% {
    width: 80%;
  }
}

.intro-1 {
  width: 170px;
  margin: 0 auto;
  color: #ddd;
  font-style: italic;
  line-height: 18px;
}
.intro-1 a{
  color: #ddd
}
.intro-1 a:hover{
  text-decoration:underline
}
.card-info-1 {
   box-sizing: border-box;
  padding: 0;
  width: 100%;
  /* position: absolute; */
  bottom: -40px;
  left: 0;
  margin: 0 auto;
  padding: 0 50px;
  font-style: 16px;
  line-height: 24px;
  z-index: 20;
  opacity: 0;
  background-color: #01060682;
  height:100%;
  transition: bottom 0.64s, opacity 0.63s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.card-info-1 a{
  display:block;
  width:100px;
  margin:15px auto;
  background:#fff;
  color:#444;
  padding:3px 10px;
  border-radius:2px;
  font-size:0.8em
}
.card-info-1 a:hover{
  background: #8e7c49;
  color:#fff;
}
.card-info-1 a:hover span{
   filter: brightness(10);
   opacity:1
}


/* card-3 */
.blog-card-2 {
  /* max-width: 550px; */
  width: 300px;;
  height: 400px;
  /* position: absolute; */
  /* font-family: 'Droid Serif', serif; */
  font-family: "Ysabeau Infant";
  color:#fff;
  /* top: 20%; */
  /* right: 0;   */
  /* left: 60px; */
  margin: 0 auto;
  overflow: hidden;
  border-radius: 0px;
  box-shadow: 0px 10px 20px -9px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition:all 0.4s;
  background: url(../src/Images/card3.png) center no-repeat;
  background-size: cover;
  opacity: 1;
  margin-top: 10px;
  /* background-size: 100%;  */
}
.blog-card-2 a{ 
color:cornsilk;
  text-decoration:none;
  transition:all 0.2s
}
.blog-card-2 .color-overlay-2 {
  background: rgba(64, 84, 94,0.5);
  width: 300px;
  height: 500px;
  /* position: absolute; */
  z-index: 10;
  top: 0;
  left: 0;
  transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}
.blog-card-2 .gradient-overlay-2 {  
  background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.6) 21%);
  width: 550px;
  height: 500px;
  /* position: absolute; */
  top: 350px;
  left: 0;
  z-index: 15;
}
.blog-card-2:hover{
    box-shadow: 0px 18px 20px -9px rgba(0, 10, 30, 0.75);
}
.blog-card-2:hover .card-info-2 {
  opacity: 1;
  bottom: 100px;
}
.blog-card-2:hover .color-overlay-2 {
  background: rgba(64, 64, 70,0.8);
}
.blog-card-2:hover .title-content-2{
  margin-top:15px
}
.title-content-2 {
  text-align: center;
  margin: 170px 0 0 0;
  /* position: absolute; */
  z-index: 20;
  width: 100%;
  top: 0;
  left: 0;
  transition:all 0.6s
}

.blog-card-2:hover h3:after{
 
  animation: changeLetter 0.3s 1 linear;
  width:80%
}

.blog-card-2 h3,h1 {
  font-size: 1.9em;
  font-weight: 400;
  letter-spacing: 1px;
  /* font-family: 'Abril Fatface', serif; */
  font-family: "Ysabeau Infant";
  margin-bottom: 0;
  display:inline-block;
}
.blog-card-2 h3 a{  
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);  
  transition:all 0.2s
}
.blog-card-2 h3 a:hover{
  text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.95);
}

@keyframes changeLetter {
  0% {
    width: 10%;
  }
  100% {
    width: 80%;
  }
}

.intro-2 {
  width: 170px;
  margin: 0 auto;
  color: #ddd;
  font-style: italic;
  line-height: 18px;
}
.intro-2 a{
  color: #ddd
}
.intro-2 a:hover{
  text-decoration:underline
}
.card-info-2 {
   box-sizing: border-box;
  padding: 0;
  width: 100%;
  /* position: absolute; */
  bottom: -40px;
  left: 0;
  margin: 0 auto;
  padding: 0 50px;
  font-style: 16px;
  line-height: 24px;
  z-index: 20;
  opacity: 0;
  background-color: #01060682;
  height:100%;
  transition: bottom 0.64s, opacity 0.63s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.card-info-2 a{
  display:block;
  width:100px;
  margin:15px auto;
  background:#fff;
  color:#444;
  padding:3px 10px;
  border-radius:2px;
  font-size:0.8em
}
.card-info-2 a:hover{
  background: #8e7c49;
  color:#fff;
}
.card-info-2 a:hover span{
   filter: brightness(10);
   opacity:1
}
/* @media (max-width:750px)
{
  .blog-card-2{
    margin-top: "500px";
  }
  .utility-info {
    text-align:center;
  }
  .utility-info ul{
    width:100%;
    margin:0;
    box-sizing:border-box
  }
  .utility-info li{
    width:49%;
    display:inline-block;
    box-sizing:border-box;
    margin:0
  }
  
} */

/* @media (max-width:500px){
  .utility-info li:last-of-type{
    width:100%
  }
  .card-info{
    display:none
  }
  .blog-card:hover .title-content,.title-content{
  margin-top:40px
}
.blog-card{
  height:300px
}
.blog-card h3{
  font-size:1.3em
}
.intro{
  font-size:0.8em
}
.blog-card-2{
  margin-top: "500px";
}
} */
/* card-4 */
.blog-card-3 {
  /* max-width: 550px; */
  width: 300px;;
  height: 400px;
  /* position: absolute; */
  /* font-family: 'Droid Serif', serif; */
  font-family: "Ysabeau Infant";
  color:#fff;
  /* top: 20%; */
  /* right: 0;   */
  /* left: 60px; */
  margin: 0 auto;
  overflow: hidden;
  border-radius: 0px;
  box-shadow: 0px 10px 20px -9px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition:all 0.4s;
  background: url(https://img.freepik.com/free-photo/customer-relationship-management-concept_23-2150038409.jpg?w=1060&t=st=1687155326~exp=1687155926~hmac=0796985daaa3de1c66288493d71561a82367e449647c2dff8b89839dc114d57b) center no-repeat;
  /* background-size: 100%; */
  opacity: 1;
  margin-top: 10px;
}
.blog-card-3 a{ 
color:cornsilk;
  text-decoration:none;
  transition:all 0.2s
}
.blog-card-3 .color-overlay-3 {
  background: rgba(64, 84, 94,0.5);
  width: 300px;
  height: 500px;
  /* position: absolute; */
  z-index: 10;
  top: 0;
  left: 0;
  transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}
.blog-card-3 .gradient-overlay-3 {  
  background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.6) 21%);
  width: 550px;
  height: 500px;
  /* position: absolute; */
  top: 350px;
  left: 0;
  z-index: 15;
}
.blog-card-3:hover{
    box-shadow: 0px 18px 20px -9px rgba(0, 10, 30, 0.75);
}
.blog-card-3:hover .card-info-3 {
  opacity: 1;
  bottom: 100px;
}
.blog-card-3:hover .color-overlay-3 {
  background: rgba(64, 64, 70,0.8);
}
.blog-card-3:hover .title-content-3{
  margin-top:15px
}
.title-content-3 {
  text-align: center;
  margin: 170px 0 0 0;
  /* position: absolute; */
  z-index: 20;
  width: 100%;
  top: 0;
  left: 0;
  transition:all 0.6s
}

.blog-card-3:hover h3:after{
 
  animation: changeLetter 0.3s 1 linear;
  width:80%
}

.blog-card-3 h3,h1 {
  font-size: 1.9em;
  font-weight: 400;
  letter-spacing: 1px;
  /* font-family: 'Abril Fatface', serif; */
  font-family: "Ysabeau Infant";
  margin-bottom: 0;
  display:inline-block;
}
.blog-card-3 h3 a{  
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);  
  transition:all 0.2s
}
.blog-card-3 h3 a:hover{
  text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.95);
}

@keyframes changeLetter {
  0% {
    width: 10%;
  }
  100% {
    width: 80%;
  }
}

.intro-3 {
  width: 170px;
  margin: 0 auto;
  color: #ddd;
  font-style: italic;
  line-height: 18px;
}
.intro-3 a{
  color: #ddd
}
.intro-3 a:hover{
  text-decoration:underline
}
.card-info-3 {
   box-sizing: border-box;
  padding: 0;
  width: 100%;
  /* position: absolute; */
  bottom: -40px;
  left: 0;
  margin: 0 auto;
  padding: 0 50px;
  font-style: 16px;
  line-height: 24px;
  z-index: 20;
  opacity: 0;
  background-color: #01060682;
  height:100%;
  transition: bottom 0.64s, opacity 0.63s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.card-info-3 a{
  display:block;
  width:100px;
  margin:15px auto;
  background:#fff;
  color:#444;
  padding:3px 10px;
  border-radius:2px;
  font-size:0.8em
}
.card-info-3 a:hover{
  background: #8e7c49;
  color:#fff;
}
.card-info-3 a:hover span{
   filter: brightness(10);
   opacity:1
}

@media screen and (max-width: 1024px) and (min-width: 348px) {
  .blog-card-2{
    width: 230px;
  }
  .blog-card-1{
    width: 230px;
  }
  .blog-card{
    width: 230px;
  }
  .blog-card-3{
    width: 230px;
  }
  
}
/* @media only screen and (max-width: 400px) {
.lightbox-image{
  height: 500px;
}
} */
.card-button{
  width: 20%;
}

.card-button:hover {
  transform: scale(1.1);
  color: #fef8f5;
}

.fontsize-small{
  font-size: 15px !important;
}

.current-card{
  width: 90%;
  align-self: center;
}
.current-card:hover{
  transform: scale(1.05);
  border: 1px dashed black;
}

.card-custom-icons{
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
#card {
  position: relative;
  top: 110px;
  width: 320px;
  display: block;
  margin: auto;
  text-align: center;
  /* font-family: 'Source Sans Pro', sans-serif; */
  font-family: "Ysabeau Infant";
}

#upper-side {
  padding: 2em;
  background-color: #8BC34A;
  display: block;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

#checkmark1 {
  font-weight: lighter;
  fill: #fff;
  margin: -3.5em auto auto 20px;
}

#status {
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1em;
  margin-top: -.2em;
  margin-bottom: 0;
}

#lower-side {
  padding: 2em 2em 5em 2em;
  background: #fff;
  display: block;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

#message {
  margin-top: -.5em;
  color: #757575;
  letter-spacing: 1px;
}

#contBtn {
  position: relative;
  top: 1.5em;
  text-decoration: none;
  background: #8bc34a;
  color: #fff;
  margin: auto;
  padding: .8em 3em;
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  border-radius: 25px;
  -webkit-transition: all .4s ease;
		-moz-transition: all .4s ease;
		-o-transition: all .4s ease;
		transition: all .4s ease;
}

#contBtn:hover {
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
  -webkit-transition: all .4s ease;
		-moz-transition: all .4s ease;
		-o-transition: all .4s ease;
		transition: all .4s ease;
}


ol.d {
  list-style-type: square;
}
.c-edxWXK{
  /* ---decorationSize: 72px; */
  width: 10px!important;
  height: 10px;
  border-radius: 50%;
  color: hsla(44, 97%, 59%, 1);
  position: relative;
  flex-shrink: 0;
  border: 1px solid hsla(44, 97%, 59%, 1);
  font:2.5000rem / 6.0625rem '__Montserrat_5a53e2', '__Montserrat_Fallback_5a53e2';
  margin-right: 40px;
  text-align: right;
}

.toast-body{
  z-index: 99999 !important;
}

/* cta button design */
.learn-more-btn {
  border: 0;
  width: 145px !important;
  background-color: #fff0;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding:0 !important;
  text-decoration: none;
 
}
.learn-more-btn:hover::before {
  width: 100%;
  height: 100%;
  /* color: #000; */
}
.learn-more-btn.learn-more-border .circle {
  background: 0 0;
}
.learn-more-btn .circle {
  width: 36px;
  height: 36px;
  /* background-color: #0729e9; */
  border-radius: 100%;
  margin-right: 10px;
  position: relative;
}
.learn-more-btn:hover .circle::before {
  top: 5.5px;
  right: 0;
  bottom: 0;
  left: 3px;
  opacity: 1;
  visibility: visible;
}
.learn-more-btn:hover .circle::after {
  top: 0;
  left: 7px;
  right: -7px;
  bottom: 0;
}
.learn-more-btn.learn-more-border .circle::after, .learn-more-btn.learn-more-border .circle::before {
  border-color: #f93c03;
}
.learn-more-btn .circle::before {
  padding: 7px;
  margin: 12px;
  border-width: 1px 0 0;
  top: 4px;
  left: -7px;
  opacity: 0;
  visibility: hidden;
}
.learn-more-btn .circle::before {
  content: "";
  border-color: #fff;
  border-style: solid;
  position: absolute;
  right: 0;
  bottom: 0;
  transition: all ease-in-out .2s;
}

.learn-more-btn .circle::after, .learn-more-btn .circle::before {
  content: "";
  border-color: #fff;
  border-style: solid;
  position: absolute;
  right: 0;
  bottom: 0;
  transition: all ease-in-out .2s;
}
.learn-more-btn.learn-more-border .circle::after, .learn-more-btn.learn-more-border .circle::before {
  border-color: #f93c03;
}
.learn-more-btn .circle::after {
  margin: 14px;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  top: 0;
  left: 0;
}

.learn-more-btn .circle::after, .learn-more-btn .circle::before {
  content: "";
  border-color: #fff;
  border-style: solid;
  position: absolute;
  right: 0;
  bottom: 0;
  transition: all ease-in-out .2s;
}
.learn-more-btn .btn-text {
  transition: color ease-in .2s;
  color: #f93c03;
  z-index: 1;
  margin-bottom: 0;

  font-size: 1rem;
  font-weight: 600;
  line-height: 1.8;
}
.learn-more-btn.learn-more-border::before {
  background-color: #fff0;
  border: 1px solid #f93c03;
}
.learn-more-btn::before {
  content: "";
  width: 36px;
  height: 100%;
  background-color: #f93c03;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100px;
  transition: all ease-in-out .3s;
}



  /* home page button style */
  .demo-btn{
      border-radius: 10px;
      border: 1px solid #f93c03 !important;
      margin-left: 10px;
      color:#f93c03 !important;
      font-weight:600 !important;
      transition: background-color 0.6s ease, color 0.5s ease;
      transition-duration: 500px;
   
  }
  .demo-btn:hover{
    background-color:#fd6517 !important;
    color: #fff !important;
  }

  @media screen and (min-width: 0px) and (max-width: 425px) {
    /* .about-section .image-column .inner-column:before {
      content: '';
      position: absolute;
      width: calc(50% + 80px);
      height: calc(100% + 160px);
      top: -80px;
      left: -3px;
      background: transparent;
      z-index: 0;
      border: 24px solid #fd6517;
      justify-content: center;
      text-align: center;
      display: flex;
      margin-left: 50px;
      margin-bottom: 1000PX;
  
      
    } */
    .about-section .image-column .image-1{
      margin: 0;
    }
    .about-section .content-column .inner-column{
      padding-top: 100px;
      padding-left: 0;
    }
  .overlay-benefits{
  width: 100%;
  height: 855px;
  /* position: relative; */
  }
  .overlay-benefits-contents{
    position: relative;
    width: 100%;
    left: 0;
  }
  .benefit-section{
    display: flex;
      flex-direction: column-reverse;
  }
  .outer-box .pricing-block{
    width: 100% !important;
  }
  .price-pack{
    width: 60%;
  }
  .home-section{
    padding: 60px 0;
  }
  }

  /* cookies design */

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggler {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .toggler:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .toggler {
    background-color: #2196F3;
  }
  
  input:focus + .toggler {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .toggler:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .toggler.round {
    border-radius: 34px;
  }
  
  .toggler.round:before {
    border-radius: 50%;
  }

  /* template form design */
  .template-btn{
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    background: none;
    margin: 8px auto;
    display: block;
    padding: 12px 0px;
    width: 100%;
    font-size: 17px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    transition: 0.3s;
    border: 1px solid #057b8a;
  }
  .template-btn:hover{
    background-color: #057b8a;
    color: white;

  }
  .template-header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.template-header h3{
    position: relative;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    min-height: 24px;
    margin: 10px 0px 18px;
    padding: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: 0.3s;
    text-transform: capitalize;
  }
  .template-shadow{
    background: linear-gradient(to top, #e3e5f5, transparent);
    position: absolute;
    height: 2rem;
    width: 100%;
    left: 0;
    bottom: 0;

    transition-property: opacity;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(1, 0, 0.64, 0.64);
    opacity: 1;
  }

  .modal{
    margin: 3.75rem 0;
  }